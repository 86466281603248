.wrap {
  display: flex;
  flex-direction: column;
}
.header {
  z-index: 2;
}
.link-wrap {
  /*overflow: hidden;*/
}
.link-mobile {
  z-index: 1;
}
.link {
  z-index: 1;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}
.link:hover {
  transform: scale(1.1);
}
